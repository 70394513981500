import React, { Suspense, lazy, useEffect } from "react";
import styled from "styled-components";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import Routes from "constants/routes";
import useUser from "hooks/useUser";
import GlobalStyles from "./global-styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { LoadSpinner } from "components";
import { DefaultLayout } from "layouts";
import Loading from "components/Loading";

const LoginPage = lazy(() => import("pages/LoginPage"));

const UserManage = lazy(() => import("pages/UserManage"));
const UserDetail = lazy(() => import("pages/UserManage/UserDetail"));
const UserCreate = lazy(() => import("pages/UserManage/UserCreate"));
const StakingManage = lazy(() => import("pages/StakingManage"));
const InvestingManage = lazy(() => import("pages/InvestingManage"));
const HistoryManage = lazy(() => import("pages/HistoryManage"));
const DepositManage = lazy(() => import("pages/HistoryManage/Deposit"));
const WithdrawManage = lazy(() => import("pages/HistoryManage/Withdraw"));
const SwapManage = lazy(() => import("pages/HistoryManage/Swap"));
const Transaction = lazy(() => import("pages/HistoryManage/Transaction"));
const TransferManage = lazy(() => import("pages/HistoryManage/Transfer"));

const InviteManage = lazy(() => import("pages/InviteManage"));
const SettingManage = lazy(() => import("pages/SettingManage"));
const ChangePassword = lazy(() => import("pages/ChangePassword"));

const Errors = lazy(() => import("pages/Errors"));

const renderLoader = () => <LoadSpinner isOverlay={false} />;

const AppWrapper = styled.div`
  height: 100%;
  .ant-layout {
    height: 100%;
    .ant-layout-content {
      background-color: ${(props) => props.theme.innerContBg};
    }
  }
  .ant-layout-header {
    background: #ffffff;
    height: auto;
    padding: 0;
    line-height: inherit;
    z-index: 99;
    position: sticky;
    top: 0;
  }
`;

function App() {
  const location = useLocation();
  const { isAuthenticated, role } = useUser();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   isAuthenticated && dispatch(fetchNetwork())
  // }, [dispatch,isAuthenticated])

  function PrivateRoute({ component: TargetPage, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated === true ? (
            <TargetPage {...props} />
          ) : (
            <Redirect to={Routes.LOGIN} />
          )
        }
      />
    );
  }

  function PublicRoute({
    component: TargetPage,
    ...rest
  }: any): React.ReactElement {
    return (
      <Route
        {...rest}
        render={(props: any) => {
          return <TargetPage {...props} {...rest} />;
        }}
      />
    );
  }
  return (
    <AppWrapper>
      {isAuthenticated === false ? (
        <Switch>
          <Suspense fallback={renderLoader()}>
            <Redirect exact from="/" to={Routes.LOGIN} />
            <Route path={Routes.LOGIN} component={LoginPage} />
          </Suspense>
        </Switch>
      ) : (
        <DefaultLayout
          // pageHeading={t("STATISCAL_HEADING")}
          boardContent={
            <Switch>
              <Suspense fallback={renderLoader()}>
                <Route path={Routes.LOGIN} component={LoginPage} />
                {location.pathname === "/login" && role !== "USER" && (
                  <Redirect
                    exact
                    from="/login"
                    to={Routes.ADMIN_LIST_HISTORY_TRANSACTION}
                  />
                )}
                <Route
                  exact
                  path="/"
                  render={() => {
                    return (
                      <Redirect to={Routes.ADMIN_LIST_HISTORY_TRANSACTION} />
                    );
                  }}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_LIST_USER}
                  component={UserManage}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_DETAIL_USER + `/:id`}
                  component={UserDetail}
                />
                {/* <PrivateRoute
                  exact
                  path={Routes.ADMIN_CREATE_USER}
                  component={UserCreate}
                /> */}
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_LIST_STAKING}
                  component={StakingManage}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_LIST_INVESTING}
                  component={InvestingManage}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_LIST_HISTORY_TRANSACTION}
                  component={Transaction}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_LIST_HISTORY}
                  component={HistoryManage}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_LIST_HISTORY_DEPOSIT}
                  component={DepositManage}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_LIST_HISTORY_WITHDRAW}
                  component={WithdrawManage}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_LIST_INVITER}
                  component={InviteManage}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_LIST_HISTORY_SWAP}
                  component={SwapManage}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_LIST_HISTORY_TRANSFER}
                  component={TransferManage}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_SETTING}
                  component={SettingManage}
                />
                <PrivateRoute
                  exact
                  path={Routes.ADMIN_CHANGE_PASS}
                  component={ChangePassword}
                />

                <PublicRoute
                  exact
                  path={Routes.ADMIN_ERRORS}
                  component={Errors}
                />
              </Suspense>
            </Switch>
          }
        />
      )}
      <Loading />
      <GlobalStyles />
      <CssBaseline />
    </AppWrapper>
  );
}

export default App;
