import styled from 'styled-components'
import BgSideBar from 'assets/images/sidebar-2.jpg';

export const Sidebar = styled.div`
.sidebar{
  width: 280px;
  opacity: 1 !important;
  transition: all 0.3s ease-in-out;
  border-right: 1px rgba(0, 0, 0, 0.12) solid;
  height: 100%;
  background-color: #fff;
  .logo-icon{
    margin-right: 7px;
    margin-left: 7px;
    width: 34px;
  }

  .sidebar-scroll{
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100% - 70px);
    &> .scrollbar-container{
      /* padding: 0 15px; */
      scroll-behavior: smooth;
    }
  }

  .sidebar-content{
    z-index: 9;
    height: 100%;
  }

  .user-info{
    padding: 20px 15px;
  }
  .user-info-detail{
    p{
      margin: 0;
      font-size: 13px;
    }
  }
  .user-info-name{
    color: #222222;
  }
  .user-info-role{
    color: #6b778c;
    text-transform: capitalize;
  }

  .sub-info {
    display: block;
    align-items: center;
    color: #6b778c;
    font-weight: 500;
    /* margin-top: 10px; */
    height: 48px;
    align-items: center;
    padding: 14px 10px;
    border-radius: 16px;
    span{
      font-weight: bold;
    }
    &.active {
      box-shadow: none;
      background-color: rgba(86, 100, 210, 0.04);
      outline: none;
    }
    &:hover {
      outline: none;
      box-shadow: none;
      background-color: rgba(86, 100, 210, 0.04);
    }
    .code-name {
      text-align: center;
      font-size: 14px;
      width: 30px;
      margin-right: 15px;
      float: left;
      line-height: 1.5em;
    }
    .sub-info-item{
      margin: 0;
      line-height: 1.5em;
    }
  }

  .user-info-top{
    display: flex;
    align-items: center;
    position: relative;
    background: #f4f5f7;
    border-radius: 16px;
    display: flex;
    overflow: hidden;
    padding: 10px 8px;
    .avatar{
      height: 34px;
      width: 34px;
      border-radius: 50%;
      margin-right: 11px;
    }
    .icon-arrow {
      color: #ffffff;
      position: absolute;
      right: 10px;
      top: 0;
      height: 34px;
      line-height: 34px;
    }
    span{
      font-size: 14px;
      color: #ffffff;
    }
  }
  .sidebar-head{
    height: 70px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid hsla(0,0%,100%,.3);
    margin: 0 15px;
    .logo{
      display: inline-block;
      img{
        height: 30px;
      }
    }
  }
  .menu-list{
    padding: 15px;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    .title-list{
      font-size: 12px;
      text-transform: uppercase;
      font-weight: bold;
      padding: 20px 0;
      margin: 0;
    }
    .list-item{
      padding: 0;
      margin: 5px 0;
      flex-direction: column;
      min-height: 50px;
      border-radius: 16px;
      /* &:first-child{
        .navigate-menu{
          box-shadow: 0 12px 20px -10px rgb(86 100 210 / 28%), 0 4px 20px 0 rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(86 100 210 / 20%) !important;
          background-color: #5664d2 !important;
        }
      } */
      .MuiTouchRipple-root{
        display: none;
      }
      .navigate-menu {
        display: flex;
        text-decoration: none;
        font-size: 18px;
        align-items: center;
        font-weight: bold;
        color: #6b778c;
        border-radius: 16px;
        span{
          font-weight: bold;
        }
        svg{
          color: #6b778c;
        }
        &:hover{
          color: #6b778c;
        }
      }
      >.navigate-menu:hover {
        background-color: rgba(86, 100, 210, 0.04);
      }
      &.active{
        >.navigate-menu{
          background-color: rgba(86, 100, 210, 0.04);
          /* box-shadow: 0 12px 20px -10px rgb(86 100 210 / 28%), 0 4px 20px 0 rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(86 100 210 / 20%) !important; */
          /* background-color: #5664d2 !important; */
          color: #5664d2;
          svg{
            color: #5664d2;
          }
        }
      }
      &:hover{
        background-color: transparent !important;
      }
    }
    .navigate-menu{
      padding: 10px 30px 10px 15px;
      width: 100%;
      border-radius: 3px;
      display: flex;
      align-items: center;
      position: relative;
      .MuiListItemIcon-root{
        min-width: auto;
        margin-right: 15px;
      }
      .arrow{
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
      }
      &:hover{
        background-color: rgba(200, 200, 200, 0.2);
        color: #ffffff;
      }
    }
    .list-subMenu{
      width: 100%;
      .submenu{
        display: flex;
        align-items: center;
      }
    }
  }

  .investors{
    margin-top: 20px;
    /* padding-bottom: 20px;
    border-bottom: 1px solid hsla(0,0%,100%,.3); */
  }

  .hiden-on-close{
    position: relative;
    white-space: nowrap;
    transform: translate3d(0px, 0, 0);
    transition: all 0.3s ease-in-out;
    span{
      white-space: nowrap;
    }
  }
  
  &.close{
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    text-shadow: none;
    &:not(:hover){
      width: 82px; 
      > .MuiDrawer-paper{
        width: 82px;
      } 
      .hiden-on-close{
        opacity: 0;
        transform: translate3d(-25px, 0, 0);
        visibility: hidden;
      }
    }
  }
}
`

export const Drawer = styled.div<{open?: boolean}>`

`


export const TypeMember = styled.div`
  margin-top: 15px;
`