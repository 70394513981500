import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Overlay from "components/Overlay/Overlay";
import usePopup from 'hooks/usePopup'
import { useDispatch } from 'react-redux'
import { setPopup, hidePopup } from "features/popup";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import Fab from '@material-ui/core/Fab';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';

import { Modal, useModal } from "../Modal";
import {
  // StyleDialog,
} from "./style";

const AccModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  max-width: 100%;
  background-color: transparent;
  @media screen and (min-width: 320px) and (max-width: 375px) {
    margin: 0px 10px;
  }
`;

const Wrapper = styled.div`
  width: 250px;
  height: 250px;
  background-color: #fff;
  z-index: 99;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
`;

const Message = styled.div`
  margin: 10px 0;
`;


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonWrapper: {
    width: 65,
    height: 65,
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonFail: {
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
  fabProgress: {
    // color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  buttonOk: {
    marginTop: 10,

  }
}));

function Loading(props) {
  const classes = useStyles();

  const dispatch = useDispatch()

  const hideDialog = () => {
    dispatch(hidePopup())
  };
  const { isPopup, type, message } = usePopup()

  useEffect(() => {
    if (type === undefined && isPopup) {
      setTimeout(function () {
        hideDialog();
      }, 10000);
    }
  }, [type, isPopup]);

  const buttonClassname = clsx({
    [classes.buttonWrapper]: type || !type,
    [classes.buttonSuccess]: type === 'success',
    [classes.buttonFail]: type === 'fail',
  });

  return (
    <>
      {isPopup &&
        <AccModal >
          <Overlay show />
          <Wrapper>
            <div className={classes.wrapper}>

              <Fab
                aria-label="save"
                color="primary"
                className={buttonClassname}
              >
                {
                  type === 'success' &&
                  <CheckIcon fontSize={'large'} />
                }
                {
                  type === 'fail' &&
                  <CloseIcon fontSize={'large'} />
                }
              </Fab>

              {!type && <CircularProgress size={77} className={classes.fabProgress} />}
            </div>
            {
              type === 'fail' &&
              <Message>
                {message}
              </Message>
            }
            {
              type && <Button size="medium" className={classes.buttonOk} variant="outlined" onClick={hideDialog}>
                Ok
              </Button>
            }

          </Wrapper>
        </AccModal>

      }
    </>

  );
}


export default Loading;
