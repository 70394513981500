const Routes = {
  LOGIN: '/login',
  HOME: '/home',
  REGISTER: '/register',


  ADMIN_LIST_PRODUCT: '/admin/list-product',
  ADMIN_CREATE_PRODUCT: '/admin/detail-product',

  ADMIN_LIST_TRADE: '/admin/list-history-trade',
  ADMIN_DETAIL_TRADE: '/admin/detail-history-trade',

  ADMIN_LIST_NETWORK: '/admin/list-network',

  ADMIN_LIST_USER: '/admin/list-user',
  ADMIN_DETAIL_USER: '/admin/detail-user',
  ADMIN_CREATE_USER: '/admin/create-user',

  ADMIN_LIST_STAKING: '/admin/list-staking',
  ADMIN_LIST_INVESTING: '/admin/list-investing',

  ADMIN_LIST_HISTORY: '/admin/list-history',


  ADMIN_LIST_HISTORY_DEPOSIT: '/admin/list-history-deposit',
  ADMIN_LIST_HISTORY_WITHDRAW: '/admin/list-history-withdraw',
  ADMIN_LIST_HISTORY_SWAP: '/admin/list-history-swap',
  ADMIN_LIST_HISTORY_TRANSFER: '/admin/list-history-transfer',
  ADMIN_LIST_HISTORY_TRANSACTION: '/admin/list-transaction',

  ADMIN_LIST_HISTORY_STAKING: '/admin/list-history-staking',

  ADMIN_SETTING: '/admin/setting',

  ADMIN_CHANGE_PASS: '/admin/change-pass',

  ADMIN_LIST_INVITER: '/admin/list-inviter',

  ADMIN_REPORT: '/admin/report',

  ADMIN_LIST_BONUST: '/admin/list-bonus',


  ADMIN_ERRORS: '/admin/errors',
  WHITE_LIST: '/white-list',
  REGISTERED: '/registered',
  REJECTED: '/rejected',

  TEST: '/admin/test'
}

export default Routes