const Endpoints = {
  DOWNLOAD: '/report',
  LOGIN: '/api/v1/public/auth/login',
  USER_LIST: '/api/v1/public/user/list',
  USER_LIST_TRADE: '/api/v1/public/user/list-trade',
  GET_USER_DETAIL: '/api/v1/public/user/details',
  GET_USER_CREATE: '/api/v1/public/user/create',
  GET_USER_UPDATE: '/api/v1/public/user/edit',
  GET_USER_DELETE: '/api/v1/public/user/delete',
  GET_USER_CHANGE_PASS: '/api/v1/public/auth/change-password',
  GET_SETTING: '/api/v1/public/settings/details ',

  GET_DEPOSIT_HISTORY_LIST: '/api/v1/public/deposit-history/list-by-user',
  GET_WITHDRAW_HISTORY_LIST: '/api/v1/public/withdraw-history/list-by-user',
  GET_SWAP_HISTORY_LIST: '/api/v1/public/swap-history/list-by-user',
  GET_TRANSACTION_HISTORY_LIST: '/api/v1/public/transaction/list-by-user',
  GET_TRANSFER_HISTORY_LIST: '/api/v1/public/transfer-history/list-by-user',

  GET_STAKING_LIST: '/api/v1/public/package/list-staking',
  GET_INVESTING_LIST: '/api/v1/public/package/list-investing',
  GET_PACKAGE_UPDATE: '/api/v1/public/package/update',

  GET_COPY_TRADE_CREATE: '/api/v1/public/copy-trade/create',
  GET_COPY_TRADE_UPDATE: '/api/v1/public/copy-trade/update',
  GET_COPY_TRADE_BUY: '/api/v1/public/copy-trade/buy',
  GET_COPY_TRADE_CANCEL: '/api/v1/public/copy-trade/cancel-all-by-symbol',
  GET_COPY_TRADE_SELL: '/api/v1/public/copy-trade/sell',
  GET_COPY_TRADE_CHANGE_STATUS_PAID: '/api/v1/public/user/change-status-paid-for-copy-trade',
  GET_COPY_TRADE_CHANGE_STATUS_DEPOSIT: '/api/v1/public/user/change-status-deposit',

  GET_LOG_LIST: '/api/v1/public/order-log/list',
  GET_APIKEY_LOG_LIST: '/api/v1/public/apikey-log/list',

  GET_HISTORY_TRADE_LIST: '/api/v1/public/order/list-order-by-user',
  GET_LIST_ORDER_BY_TRADE: '/api/v1/public/order/list-order-by-trade',

  GET_BONUS_LIST: '/api/v1/public/bonus/list-bonus-by-user',
  GET_BONUS_UPDATE: '/api/v1/public/user/update-bonus',


  CHANGE_PASSWORD: '/api/v1/public/auth/change-password',

  NETWORK_LIST: '/api/v1/public/market/list',
  NETWORK_DETAIL: '/api/v1/public/market/detail',
  NETWORK_UPDATE: '/api/v1/public/market/update',
  NETWORK_CREATE: '/api/v1/public/market/create',
  NETWORK_LIST_BY_ADMIN: '/api/v1/public/market/list-by-admin',

  INVITER_LIST: '/api/v1/public/bonus/list-bonus-by-user',

  
  AGENCY_LIST: '/inviter/gets',
  EDIT_AGENCY: '/wallet-pool/add-remain',
  CHECK_WALLET: '/wallet-pool/list-by-dev',
  TRANSFER_HISTORY: '/transaction-delegate/list-by-dev/transfer',

  DELETE_WALLET: '/wallet-pool/remove',
  WALLET_REMAIN: '/wallet-pool/add-remain',
  TRANSFER_COIN: '/transaction-delegate/send',
  TRANSACTION_LIST: '/transaction-delegate/list-by-dev',
  USER_BALANCE: '/balance',

  GET_LOCAL_BRAND: '/api/v1/third-api/local-brand',
  GET_MODEL_LIST: '/api/v1/third-api/model-list',

  GET_ALL_MEMBER: '/api/v1/admin/list-user',
  GET_MEMBER_DETAIL: '/api/v1/admin/detail-user',
  GET_STATISTIC_USER: '/api/v1/admin/statistic-user',
  GET_USED_POINT: '/api/v1/admin/detail-user/point-used',
  UPDATE_USER_STATUS: '/api/v1/admin/update-user-status',
  CHANGE_COMMENT_STATUS: '/api/v1/comments/update-status',
  CHANGE_CAR_TALK_STATUS: '/api/v1/cartalk/update-status',
  GET_ALL_COMMENTS: '/api/v1/comments',

  GET_ALL_CAR_INFO: '/api/v1/cartalk',
  GET_ALL_CAR_CATEGORY: '/api/v1/category-cartalk',

  GET_PAYMENT_HISTORY: '/api/v1/point-center/reward-history',
  GET_POINT_STATISTIC: '/api/v1/point-center/statistical-point',
  GET_POINT_CONFIG: '/api/v1/point-center/point-reward-config',

  GET_ALL_NOTIFICATION: '/api/v1/admin/acarz-notification',
  EDIT_NOTIFICATION: '/api/v1/admin/acarz-notification/update',

  GET_ALL_QNA: '/api/v1/admin/qnas',
  GET_QNA_CATEGORY: '/api/v1/admin/qnas/categories',
  ANSWER_QNA: '/api/v1/admin/qnas/answer',

  SEND_PUSH: '/api/v1/fcm/push-topic',
  GET_PUSH_HISTORY: '/api/v1/fcm/push-history',

  GET_ALL_PRODUCT: '/api/v1/public/product/getAll',
  GET_DETAIL_PRODUCT: '/api/v1/public/product/detail',
  GET_UPDATE_PRODUCT: '/api/v1/public/product/update',
  GET_ADD_PRODUCT: '/api/v1/public/product/add',
  GET_DELETE_PRODUCT: '/api/v1/public/product/delete',

  GET_ALL_PROJECT: '/api/v1/public/project/getAll',
  GET_DETAIL_PROJECT: '/api/v1/public/project/detail',
  GET_ADD_PROJECT: '/api/v1/public/project/add',
  GET_UPDATE_PROJECT: '/api/v1/public/project/update',
  GET_DELETE_PROJECT: '/api/v1/public/project/delete',

  GET_ALL_NEWS: '/api/v1/public/post/getAll',
  GET_GET_TOP: '/api/v1/public/post/getTop',
  GET_GET_NEWS: '/api/v1/public/post/getNews',
  GET_ADD_NEWS: '/api/v1/public/post/add',
  GET_UPDATE_NEWS: '/api/v1/public/post/update',
  GET_DELETE_NEWS: '/api/v1/public/post/delete',
  GET_DETAIL_NEWS: '/api/v1/public/post/detail',


  GET_ALL_DOCUMENTS: '/api/v1/public/document/getAll',
  GET_ADD_DOCUMENTS: '/api/v1/public/document/add',
  GET_UPDATE_DOCUMENTS: '/api/v1/public/document/update',
  GET_DELETE_DOCUMENTS: '/api/v1/public/document/delete',


  GET_ALL_CONTACT: '/api/v1/public/contact/getAll',
  CONFIRM_TRANSACTION_BUY: '/api/v1/public/transaction/confirm-buy',
  CONFIRM_TRANSACTION_SELL: '/api/v1/public/transaction/confirm-sell',
}

export default Endpoints