import axios from 'services/axiosInstance'
import Endpoints from 'constants/endpoints'

export interface User {
  userid: string,
  role: string,
  accessToken: string,
  email: string,
  isPaidForCopyTrade ?: any
  bonusDebt?: any
}

export interface UserResult {
  success: boolean | null,
  data: User,
  msgCode: string | null,
  errors: object | null
}

export async function userLogin(formData: object): Promise<UserResult> {
  try {
    const response:any = await axios().post(Endpoints.LOGIN, formData)
    console.log("response123456", response)
    // const success = response?.result
    return {
      success: response['result'],
      data: {
        userid: response?.data['id'],
        accessToken: response?.data['accessToken'],
        role: response?.data['userRole'],
        email: response?.data['email'],
        isPaidForCopyTrade: response?.data['isPaidForCopyTrade'],
        bonusDebt: response?.data['bonusDebt'] ?? 0
        
      },
      msgCode: response['msgCode'],
      errors: response['errors']
    }
  } catch (error) {
    throw error
  }
}

export async function getAllUser(params?: any) {
  return await axios().get(Endpoints.USER_LIST, {params})
    .then(response => {
      // console.log("response", response);
      return response
    })
    // 응답(실패)
    .catch(error => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        console.log("error.response", error.response);
        return error.response
      } else if (error.request) {
        console.log("error.request", error.request);
        return error.request
        // client never received a response, or request never left
      } else {
        console.log("error", error);
        return error
        // anything else
      }
    })
}

export async function getAllUserListTrade(params?: any) {
  return await axios().get(Endpoints.USER_LIST_TRADE, {params})
    .then(response => {
      // console.log("response", response);
      return response
    })
    // 응답(실패)
    .catch(error => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        console.log("error.response", error.response);
        return error.response
      } else if (error.request) {
        console.log("error.request", error.request);
        return error.request
        // client never received a response, or request never left
      } else {
        console.log("error", error);
        return error
        // anything else
      }
    })
}


export async function getDetailUser(params?: any) {
  return await axios().get(Endpoints.GET_USER_DETAIL, {params})
    .then(response => {
      // console.log("response", response);
      return response
    })
    // 응답(실패)
    .catch(error => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        console.log("error.response", error.response);
        return error.response
      } else if (error.request) {
        console.log("error.request", error.request);
        return error.request
        // client never received a response, or request never left
      } else {
        console.log("error", error);
        return error
        // anything else
      }
    })
}

export async function CreateUser(data?: any) {
  return await axios().post(Endpoints.GET_USER_CREATE, data)
    .then(response => {
      // console.log("response", response);
      return response
    })
    // 응답(실패)
    .catch(error => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        console.log("error.response", error.response);
        return error.response
      } else if (error.request) {
        console.log("error.request", error.request);
        return error.request
        // client never received a response, or request never left
      } else {
        console.log("error", error);
        return error
        // anything else
      }
    })
}

export async function UpdateUser(data?: any) {
  return await axios().post(Endpoints.GET_USER_UPDATE, data)
    .then(response => {
      // console.log("response", response);
      return response
    })
    // 응답(실패)
    .catch(error => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        console.log("error.response", error.response);
        return error.response
      } else if (error.request) {
        console.log("error.request", error.request);
        return error.request
        // client never received a response, or request never left
      } else {
        console.log("error", error);
        return error
        // anything else
      }
    })
}

export async function DeleteUser(data?: any) {
  return await axios().post(Endpoints.GET_USER_DELETE, data)
    .then(response => {
      // console.log("response", response);
      return response
    })
    // 응답(실패)
    .catch(error => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        console.log("error.response", error.response);
        return error.response
      } else if (error.request) {
        console.log("error.request", error.request);
        return error.request
        // client never received a response, or request never left
      } else {
        console.log("error", error);
        return error
        // anything else
      }
    })
}

export async function getChangePass(payload) {
  return await axios().post(Endpoints.GET_USER_CHANGE_PASS, payload)
    .then(response => {
      // console.log("response", response);
      return response
    })
    // 응답(실패)
    .catch(error => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        console.log("error.response", error.response);
        return error.response
      } else if (error.request) {
        console.log("error.request", error.request);
        return error.request
        // client never received a response, or request never left
      } else {
        console.log("error", error);
        return error
        // anything else
      }
    })
}

export async function getSetting(payload?: any) {
  return await axios().get(Endpoints.GET_SETTING, payload)
    .then(response => {
      // console.log("response", response);
      return response
    })
    // 응답(실패)
    .catch(error => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        console.log("error.response", error.response);
        return error.response
      } else if (error.request) {
        console.log("error.request", error.request);
        return error.request
        // client never received a response, or request never left
      } else {
        console.log("error", error);
        return error
        // anything else
      }
    })
}

export async function setSetting(payload?: any) {
  return await axios().post(Endpoints.GET_SETTING, payload)
    .then(response => {
      // console.log("response", response);
      return response
    })
    // 응답(실패)
    .catch(error => {
      if (error.response) {
        // client received an error response (5xx, 4xx)
        console.log("error.response", error.response);
        return error.response
      } else if (error.request) {
        console.log("error.request", error.request);
        return error.request
        // client never received a response, or request never left
      } else {
        console.log("error", error);
        return error
        // anything else
      }
    })
}