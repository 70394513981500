import { createGlobalStyle } from 'styled-components'
import { Themes } from 'configs/theme'
import Fonts from 'assets/fonts/font'

const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: 'RussoOne-Regular';
  src: url(${Fonts.RussoOneRegular}) format('truetype');
}
 @font-face {
  font-family: 'WorkSans-Light';
  src: url(${Fonts.WorkSansLight}) format('truetype');
}
@font-face {
  font-family: 'WorkSans-Regular';
  src: url(${Fonts.WorkSansRegular}) format('truetype');
}
@font-face {
  font-family: 'WorkSans-Medium';
  src: url(${Fonts.WorkSansMedium}) format('truetype');
}
@font-face {
  font-family: 'WorkSans-SemiBold';
  src: url(${Fonts.WorkSansSemiBold}) format('truetype');
}

@font-face {
  font-family: 'WorkSans-Bold';
  src: url(${Fonts.WorkSansBold}) format('truetype');
}

  html,
  body {
    height: 100%;
    width: 100%;
    font-size: 14px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  body {
    /* overflow: hidden; */
    background-color: #F9FAFC !important;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  body.fontLoaded {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  #root {
    height: 100%;
  }

  a{
    &:hover{
      text-decoration: none;
    }
  }

  p,
  label {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    line-height: 1.5em;
  }
  
  .image {
    max-width: 100%;
    object-fit: cover;
  }

  img{
    display: block;
    max-width: 100%;
  }
  
  .ant-form-item-children-icon {
    top: 50% !important;
  }
  .login__logo-block {
    //es
    background-color: ${Themes['default'].containerBg};
  }
  .ant-menu-vertical .ant-menu-item {
    margin: 0 auto;
  }
  .ant-menu-inline .ant-menu-item {
    margin: 0 auto;
  }
  .anticon {
    /* vertical-align: 0.2em !important; */
  }
  tr.ant-table-expanded-row > td {
    background: ${Themes['default'].mainWhite};
  }
  .white-list__modal-title {
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
  }
  .input__label {
    color: ${Themes['default'].inputLabel};
  }
  .ant-layout-sider {
    flex: 0 0 250px !important;
    max-width: 250px !important;
    min-width: 250px !important;
    width: 250px !important;
  }
  .ant-layout-sider-collapsed {
    flex: 0 0 80px !important;
    max-width: 80px !important;
    min-width: 80px !important;
    width: 80px !important;
  }

  .icon-search{
    margin:  0 10px;
  }

  .date-filter-option{
    min-width: 200px !important;
    height: 34px !important;
  }

  .custom-pagination{
    display: flex;
    align-items: center;
    justify-content: center;
    .btn-pagination{
      background-color: transparent !important;
      height: 36px;
      width: 36px;
      min-width: 36px;
      margin: 5px;
      padding: 0;
      border-radius: 100%;
      &.disable{
        color: #999999;
        cursor: default;
      }
      &.enable{
        color: #222222;
      }
      &:hover{
        background-color: rgba(86,100,210,0.04) !important;
      }
    }
  }
  
  .mg-bt-20{
    margin-bottom: 20px;
  }
  .block-title{
    padding: 24px;
    color: #222222;
    font-weight: bold;
    border-bottom: 1px rgba(0,0,0,0.12) solid;
    margin: 0;
    font-size: 20px;
  }
  .ql-toolbar{
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .ql-container{
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .ql-editor{
    min-height: 300px;
  }
  .ant-table-wrapper{
    overflow-x: auto;
  }
`;

export default GlobalStyle
