import React, { useState, useEffect, memo } from "react";
import {
  LineChartOutlined,
  UserOutlined,
  LockOutlined,
  BankOutlined,
  CodepenCircleOutlined,
  ContactsOutlined,
  RiseOutlined,
  FallOutlined,
  SwapOutlined,
  TransactionOutlined,
  SettingOutlined
} from "@ant-design/icons";

import Avatar from "assets/images/faces/avatar.jpg";
import useUser from "hooks/useUser";

import * as S from "./style";
import { SideBar, Header } from "components";
import { useTranslation } from "react-i18next";
import Routes from "constants/routes";
import VN from "assets/images/flags/VN.png";
import En from "assets/images/flags/GB.png";
import reactLocalStorage from "utils/localStorage";
interface Props {
  boardContent?: any;
  getInfoWallet?: () => void;
}

const dataSidebar = [
  // {
  //   titleMenu: `List User`,
  //   icon: <UserOutlined />,
  //   link: Routes.ADMIN_LIST_USER,
  //   subMenu: null,
  // },
  // {
  //   titleMenu: 'Create a new User',
  //   icon: <UserAddOutlined />,
  //   link: Routes.ADMIN_CREATE_USER,
  //   subMenu: null
  // },
  // {
  //   titleMenu: `List Staking`,
  //   icon: <CodepenCircleOutlined />,
  //   link: Routes.ADMIN_LIST_STAKING,
  //   subMenu: null,
  // },
  // {
  //   titleMenu: `List Investing`,
  //   icon: <LineChartOutlined />,
  //   link: Routes.ADMIN_LIST_INVESTING,
  //   subMenu: null,
  // },
  {
    titleMenu: `List transaction`,
    icon: <SwapOutlined />,
    link: Routes.ADMIN_LIST_HISTORY_TRANSACTION,
    subMenu: null,
  },
  // {
  //   titleMenu: `List History`,
  //   icon: <BankOutlined />,
  //   link: Routes.ADMIN_LIST_HISTORY_DEPOSIT,
  //   subMenu: [
  //     {
  //       titleMenu: `Deposit`,
  //       icon: <RiseOutlined />,
  //       // link: Routes.ADMIN_MEMBER_LIST + `/0`,
  //       link: Routes.ADMIN_LIST_HISTORY_DEPOSIT,
  //       subMenu: null,
  //     },
  //     {
  //       titleMenu: `Withdraw`,
  //       icon: <FallOutlined />,
  //       link: Routes.ADMIN_LIST_HISTORY_WITHDRAW,
  //       subMenu: null,
  //     },
  //     {
  //       titleMenu: `Swap`,
  //       icon: <SwapOutlined  />,
  //       link: Routes.ADMIN_LIST_HISTORY_SWAP,
  //       subMenu: null,
  //     },
  //     {
  //       titleMenu: `Transfer`,
  //       icon: <TransactionOutlined />,
  //       link: Routes.ADMIN_LIST_HISTORY_TRANSFER,
  //       subMenu: null,
  //     },
  //   ],
  // },
  // {
  //   titleMenu: `List Invite`,
  //   icon: <ContactsOutlined />,
  //   link: Routes.ADMIN_LIST_INVITER,
  //   subMenu: null,
  // },
  // {
  //   titleMenu: `Setting`,
  //   icon: <SettingOutlined />,
  //   link: Routes.ADMIN_SETTING,
  //   subMenu: null,
  // },
  // {
  //   titleMenu: `Create a new Trade`,
  //   icon: <AddCircleIcon />,
  //   link: Routes.ADMIN_CREATE_COPY_TRADE,
  //   subMenu: null
  // },
  // {
  //   titleMenu: `Trade history`,
  //   icon: <ApartmentIcon />,
  //   link: Routes.ADMIN_LIST_TRADE,
  //   subMenu: null
  // },

  {
    titleMenu: `Change password`,
    icon: <LockOutlined  />,
    link: Routes.ADMIN_CHANGE_PASS,
    subMenu: null
  },

  // {
  //   titleMenu: `Tạo Trade`,
  //   icon: <DirectionsCarIcon />,
  //   link: Routes.ADMIN_DETAIL_TRADE,
  //   subMenu: null
  // },

  // {
  //   titleMenu: `${t("CREATE_PROJECT")}`,
  //   icon: <NotificationsActiveIcon />,
  //   link: Routes.ADMIN_CREATE_PROJECT,
  //   subMenu: null
  // },
  // {
  //   titleMenu: `${t("LIST_NEWS")}`,
  //   icon: <ChatIcon />,
  //   link: Routes.ADMIN_LIST_NEWS,
  //   subMenu: null
  // },
  // {
  //   titleMenu: `${t("CREATE_NEWS")}`,
  //   icon: <MonetizationOnIcon />,
  //   link: Routes.ADMIN_CREATE_NEWS,
  //   subMenu: null
  // },

  // {
  //   titleMenu: `${t("LIST_CONTACT")}`,
  //   icon: <LiveHelpIcon />,
  //   link: Routes.ADMIN_LIST_CONTACT,
  //   subMenu: null
  // },

  // {
  //   titleMenu: `${t("LIST_DOCUMENT")}`,
  //   icon: <AnnouncementIcon />,
  //   link: Routes.ADMIN_LIST_DOCUMENTS,
  //   subMenu: null
  // },

  // {
  //   titleMenu: `Setting`,
  //   icon: <SettingsIcon />,
  //   link: Routes.ADMIN_SETTING,
  //   subMenu: null
  // }
];

function DefaultLayout(props: Props) {
  const { boardContent } = props;
  const { t } = useTranslation("common");
  const { i18n } = useTranslation("common");
  const [open, setOpen] = useState(true);
  const { isAuthenticated, role } = useUser();

  const listOptions = [
    {
      src: VN,
      name: "Việt Nam",
      lang: "vn",
    },
    {
      src: En,
      name: "English",
      lang: "en",
    },
  ];

  const userInfo = {
    avtSrc: Avatar,
    useName: "Tania Andrew",
    subInfo: [
      {
        id: 1,
        codeName: "L",
        pageName: `${t("SIDEBAR_LOGOUT")}`,
        link: "#",
        isActive: false,
      },
    ],
  };

  // const dataSideBars = role === 'USER' ? dataSidebarUser:dataSidebar

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const getLangSelected = (e) => {
    i18n.changeLanguage(e.lang);
    reactLocalStorage.set("langElectricity", JSON.stringify(e));

    // console.log("default", e)
  };

  useEffect(() => {
    let langSelected = JSON.parse(
      reactLocalStorage.get("langElectricity", JSON.stringify(listOptions[0]))
    ).lang;
    i18n.changeLanguage(langSelected);
    // eslint-disable-next-line
  }, []);

  return (
    <S.Wrapper>
      <Header
        handleDrawerOpen={handleDrawerOpen}
        langSelected={getLangSelected}
        isOpen={open}
        listOptions={listOptions}
      />
      <S.Body>
        <SideBar
          userInfo={userInfo}
          sideBarMenu={dataSidebar}
          open={open}
        // infoWallet={infoWallet}
        />
        <main className="main-content">
          <div className="board-content">{boardContent}</div>
        </main>
      </S.Body>
    </S.Wrapper>
  );
}

export default memo(DefaultLayout);
